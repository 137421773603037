import { Modal, InfoModal, InfoBubble } from '@doseme/cohesive-ui'

import { IModalProps } from '../../../../../../../../types'
import { svgAllApplicableModels, svgSuggestedModels } from './constants'

import './index.scss'

export const ModelSelectionPopupModal: React.FC<IModalProps> = (props) => {
  const getModalContent = (): JSX.Element => {
    return (
      <div className='model-fit-modal'>
        <div className='model-fit-text-div'>
          <div className='model-fit-subheading'>How does DoseMeRx suggest a model?</div>
          <p className='model-fit-paragraph'>
            For supported drugs, you may see additional suggested model(s) if they better align with your patient’s
            current clinical data documented in the dosing profile.
          </p>
          <div className='model-fit-subheading mt-4'>How it's calculated:</div>
          <p className='model-fit-paragraph'>
            DoseMeRx evaluates all relevant pharmacokinetic models for the drug that suit your patient's profile. If a
            model shows a significant improvement in matching the data, it is suggested for consideration. This feature
            requires at least two serum concentrations to ensure a robust comparison. The most suitable model will
            appear at the top as the suggested model, with less suitable models ranked below in order and all other
            applicable models listed below.
          </p>
          <p>
            You can choose to continue with the suggested model, or select any applicable model you believe is most
            appropriate based on your clinical judgment.
          </p>
          {svgSuggestedModels}
        </div>
        {infoBubble()}
        <div className='model-fit-text-div'>
          <div className='model-fit-subheading'>What if there is no suggested model?</div>
          <p className='model-fit-paragraph'>
            If there is no suggested model, it means that DoseMeRx is unable to determine suitability for any applicable
            model for that drug. All applicable models will be listed for you to select the most appropriate one, based
            on your clinical judgment.
          </p>
          {svgAllApplicableModels}
        </div>
        <p className='model-fit-subheading mt-3'>
          For more information, head over to the Resources section or contact the DoseMeRx support team directly - we're
          avaiable 24/7.
        </p>
      </div>
    )
  }

  const infoBubble = () => {
    const title = <div className='model-selection-important-header'>Important information:</div>

    return (
      <InfoBubble bubbleTitle={title}>
        <div className='model-selection-paragraph-infobubble'>
          A model’s suitability within model suggestion is determined by comparing a patient’s clinical data at the
          present time with all relevant models for the drug applied.
        </div>
        <div className='model-selection-paragraph-infobubble mt-2'>
          Once more course information or data is available (i.e., recorded doses and observations), the model’s
          suitability should be re-evaluated based on individualized model fit.
        </div>
      </InfoBubble>
    )
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <InfoModal
        size='m'
        title={<div>Model selection information: <br/> Editing course model</div>}
        message={getModalContent()}
        onDismiss={() => props.setShow(false)}
        limitHeightToWindow
      />
    </Modal>
  )
}
