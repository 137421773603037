import { useState } from 'react'
import {
  StatisticsPanel,
  MessageOverlay,
  PAGINATION_GREY,
  Icons,
  IconButton,
  ITimeState,
  InfoButton,
  EMERALD
} from '@doseme/cohesive-ui'
import { format } from 'date-fns'
import { Tooltip } from 'react-tooltip'
import classnames from 'classnames'

import {
  IModelDoseRecommendation,
  IPerDoseOutcome,
  IPredictedOutcome,
  TModelType
} from '../../../../../../../../../store/dosingRecommendation/types'
import { IDefaultLimit, TLoadState } from '../../../../../../../../../store/types'
import { indPopOrGuidelineDoseStats } from './fields/indPopFields'
import { predictedOutcomeCmlAUCStats, predictedOutcomeRangeStats, predictedOutcomesStats } from './fields/sharedFields'
import { ICourseCustomTarget } from '../../../../../../../../../store/course/types'
import { TSimulationFilter, TSimulationPanelType } from '../../../types'
import { SimulationOutcomesInfoIcon } from '../components/SimulationOutcomesInfoIcon'
import { dateFnsRawDateOnly, dateFnsTableReadable } from '../../../../../../../../../constants/timeFormat'
import { isValidDSTDateTime } from '../../../../../../../../../utils/dates'

import '../index.scss'

interface IProps {
  calculationDisabled: boolean
  hospitalTimezone: string
  nextDoseAt: Date | null
  nextDoseAtTime: ITimeState
  minDate: Date | null
  maxDate: Date | null
  calculated: IModelDoseRecommendation | null
  perDoseOutcomes: IPerDoseOutcome | null
  predictedOutcome: IPredictedOutcome | null
  isDefaultDosingIntervalAssumedPredicted: boolean
  storeLoadState: TLoadState
  selectedSimulationPanelTab: TModelType
  modelPrettyName: string
  aucTargetLimit: IDefaultLimit
  peakTargetLimit: IDefaultLimit
  troughTargetLimit: IDefaultLimit
  timeAboveMICTargetLimit: IDefaultLimit
  showDosingMatrixButton: boolean
  defaultCustomTarget: ICourseCustomTarget
  activeSimTab: TSimulationFilter | null | undefined
  setShowDosingMatrixPanel: (value: boolean) => void
  setShowInfoPopupModal: (show: boolean) => void
  handleCalculation: (isGuidelineCalculation: boolean) => void
  setShowSimulateHDScheduleModal: (showSimulatePanel: TSimulationPanelType) => void
  drugModelId?: string
}

export const IndPopOrGuidelineTab: React.FC<IProps> = (props) => {
  const {
    calculationDisabled,
    hospitalTimezone,
    nextDoseAt,
    nextDoseAtTime,
    minDate,
    maxDate,
    calculated,
    perDoseOutcomes,
    predictedOutcome,
    isDefaultDosingIntervalAssumedPredicted,
    storeLoadState,
    selectedSimulationPanelTab,
    modelPrettyName,
    aucTargetLimit,
    peakTargetLimit,
    troughTargetLimit,
    timeAboveMICTargetLimit,
    showDosingMatrixButton,
    defaultCustomTarget,
    activeSimTab,
    drugModelId,
    setShowDosingMatrixPanel,
    setShowInfoPopupModal,
    handleCalculation,
    setShowSimulateHDScheduleModal
  } = props

  const [isDosingMatrixBtnHover, setIsDosingMatrixBtnHover] = useState<boolean>(false)

  const hospitalDefaultTarget = (): string => {
    if (defaultCustomTarget) {
      if (defaultCustomTarget.name === 'Trough Only') {
        return `Default target of ${defaultCustomTarget.name}: ${troughTargetLimit.default.value} ${troughTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'Peak Only') {
        return `Default target of ${defaultCustomTarget.name}: ${peakTargetLimit.default.value} ${peakTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'AUC24') {
        return `Default target of ${defaultCustomTarget.name}: ${aucTargetLimit.default.value} ${aucTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'AUC') {
        return `Default target of ${defaultCustomTarget.name}: ${aucTargetLimit.default.value} ${aucTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'Peak & Trough') {
        return `Default target of Peak: ${peakTargetLimit.default.value} ${peakTargetLimit.default.unit?.name},
          Trough: ${troughTargetLimit.default.value} ${troughTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'Extended Interval') {
        return `Default target (Extended Interval): Peak: ${peakTargetLimit.default.value} ${peakTargetLimit.default.unit?.name},
          Trough: ${troughTargetLimit.default.value} ${troughTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'Time Above MIC') {
        return `Default target of ${defaultCustomTarget.name}: ${timeAboveMICTargetLimit.default.value} ${timeAboveMICTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'Time to Trough') {
        return `Default target of ${defaultCustomTarget.name}: ${troughTargetLimit.default.value} ${troughTargetLimit.default.unit?.name}`
      }

      if (defaultCustomTarget.name === 'Cumulative AUC' && predictedOutcome?.cumulativeAUCTarget) {
        return `Total ${defaultCustomTarget.name} target: ${predictedOutcome.cumulativeAUCTarget.value.toString()} ${predictedOutcome.cumulativeAUCTarget.unit?.name}`
      }
    }

    return ''
  }

  const statPanelColWidth = defaultCustomTarget.name === 'Time Above MIC' && activeSimTab === 'indPop' ? 68 : undefined

  const renderStatisticsPanel = () => {
    if (predictedOutcome?.maxINR && predictedOutcome?.minINR) {
      return (
        <StatisticsPanel
          data={predictedOutcomeRangeStats(perDoseOutcomes)}
          boldTitles
          bgColor={PAGINATION_GREY}
          colWidth={110}
        />
      )
    }

    if (defaultCustomTarget.name === 'Cumulative AUC' && predictedOutcome) {
      return (
        <StatisticsPanel
          data={predictedOutcomeCmlAUCStats(!!calculated, predictedOutcome)}
          boldTitles
          bgColor={PAGINATION_GREY}
          colWidth={160}
        />
      )
    }

    return (
      <StatisticsPanel
        data={predictedOutcomesStats(
          !!calculated,
          perDoseOutcomes,
          predictedOutcome,
          aucTargetLimit,
          peakTargetLimit,
          troughTargetLimit,
          timeAboveMICTargetLimit
        )}
        bgColor={PAGINATION_GREY}
        boldTitles
        colWidth={statPanelColWidth}
      />
    )
  }

  const content: JSX.Element = (
    <div className='d-flex justify-content-between'>
      <div className='dose-recommendation-outer'>
        <div className='d-flex align-items-center'>
          <div
            className={classnames('sim-panel-subheading font-bold co-h6', {
              'sim-panel-subheading-indpop': showDosingMatrixButton
            })}
          >
            {modelPrettyName} Dose Recommendation{' '}
            <div>
              <InfoButton onClick={() => setShowInfoPopupModal(true)} />
            </div>
          </div>
          {showDosingMatrixButton && (
            <IconButton
              onMouseEnter={() => setIsDosingMatrixBtnHover(true)}
              onMouseLeave={() => setIsDosingMatrixBtnHover(false)}
              onClick={(e) => setShowDosingMatrixPanel(true)}
              background='none'
              className='dosing-matrix-btn'
            >
              <Icons.DosingMatrixView color={isDosingMatrixBtnHover ? EMERALD : undefined } />
            </IconButton>
          )}
        </div>
        <div className='sim-panel-calculated-subtitle'>
          {predictedOutcome?.maxINR &&
            predictedOutcome?.minINR &&
            `Target of INR: ${predictedOutcome?.minINR} — ${predictedOutcome?.maxINR}`}
          {['loading', 'updating'].includes(storeLoadState) && !activeSimTab ? 'Default target of' : null}
          {activeSimTab && activeSimTab === 'indPop' ? hospitalDefaultTarget() : <>&#8205;</>}
        </div>
        <StatisticsPanel
          data={indPopOrGuidelineDoseStats(
            calculated,
            isDefaultDosingIntervalAssumedPredicted,
            selectedSimulationPanelTab === 'guideline'
          )}
          colWidth={statPanelColWidth}
        />
      </div>
      <div
        className={classnames('predicted-outcomes-outer', {
          'indpop-predicted-outcomes-outer-time-to-trough': defaultCustomTarget.name === 'Time to Trough'
        })}
      >
        <div className='d-flex align-items-center'>
          <span
            className={classnames('sim-panel-subheading font-bold co-h6', {
              'sim-panel-subheading-indpop': showDosingMatrixButton
            })}
          >
            Predicted Outcomes
            <div className='info-icon'>
              <SimulationOutcomesInfoIcon />
            </div>
          </span>
        </div>
        <div className='sim-panel-calculated-subtitle'>Calculated by DoseMeRx</div>
        {renderStatisticsPanel()}
      </div>
    </div>
  )

  const overlaidContent = (): JSX.Element => {
    const nextDoseAtMilliseconds = nextDoseAt?.getTime()
    const withinDateRange =
      nextDoseAtMilliseconds &&
      (!minDate || nextDoseAtMilliseconds >= minDate.getTime()) &&
      (!maxDate || nextDoseAtMilliseconds <= maxDate.getTime())

    const calcTimeValid =
      withinDateRange &&
      nextDoseAt &&
      isValidDSTDateTime(format(nextDoseAt, dateFnsRawDateOnly), nextDoseAtTime, hospitalTimezone)

    const calcEnabled = calcTimeValid && !calculationDisabled

    const btnTooltipRef = 'ind-pop-daterange-tooltip'

    const disabledProps = calcEnabled
      ? {}
      : {
        btnTooltipRef,
        disabledAction: true
      }

    if (!calculated) {
      return (
        <>
          {!calcTimeValid && minDate && maxDate ? (
            <Tooltip id={btnTooltipRef} place='top'>
              Next dose time must be <br /> between&nbsp;
              <span> {format(minDate!, dateFnsTableReadable)}</span> <br /> and&nbsp;
              <span> {format(maxDate!, dateFnsTableReadable)}</span>
            </Tooltip>
          ) : null}
          <MessageOverlay
            type={storeLoadState === 'loading' || storeLoadState === 'updating' ? 'loading' : 'action'}
            actionText={<div>Calculate</div>}
            borderRadiusPx={16}
            onAction={() => {
              if (drugModelId === '55' && selectedSimulationPanelTab !== 'guideline') {
                setShowSimulateHDScheduleModal('indPop')

                return
              }
              handleCalculation(selectedSimulationPanelTab === 'guideline')
            }}
            height='150'
            zIndex={10}
            {...disabledProps}
          >
            {content}
          </MessageOverlay>
        </>
      )
    }

    return content
  }

  return <div className='full-width-overlay'>{overlaidContent()}</div>
}
