import { IHeaderItem } from '@doseme/cohesive-ui'
import { ObservableMap } from 'mobx'

import { IAdminHospitalListItem } from '../../../../store/Admin/AdminHospitalList/types'

export const searchHospitals = (
  searchText: string,
  hospitals: ObservableMap<string, IAdminHospitalListItem>
): IAdminHospitalListItem[] => {
  return [...hospitals].reduce((acc: IAdminHospitalListItem[], [key, curr]) => {
    if (curr.attributes.name.toLowerCase().includes(searchText.toLowerCase())) {
      return acc.concat({
        type: curr.type,
        id: curr.id,
        attributes: { ...curr.attributes }
      })
    }

    return acc
  }, [])
}

export const hospitalSettingsColumns = (handleSort: (colIndex: number, ascending: boolean) => void, isSuperAdmin: boolean): IHeaderItem[] => {
  const columns: IHeaderItem[] = [{
    name: 'Name',
    handleSort,
    width: isSuperAdmin ? 5 : 8
  }]

  if (isSuperAdmin) {
    columns.push({
      name: 'Features',
      width: 3
    })

    columns.push({
      name: 'ID',
      handleSort,
      width: 1
    })
  }

  columns.push({
    name: 'Time Zone',
    handleSort,
    width: isSuperAdmin ? 2 : 2
  })

  if (isSuperAdmin) {
    columns.push({
      name: '',
      width: 1
    })
  }

  return columns as IHeaderItem[]
}
